.app {
  min-height: 100vh;
  width: 100%;
}

.wrapper {
  width: 100%;
  padding-top: var(--navbar-height);
  display: flex;
}

.container {
  display: flex;
  flex-direction: column;
  width: 100%;
  /* width: calc(100% - var(--sidebar-width) - 47px * 2); */
  overflow: auto;
  /* margin-left: calc(var(--sidebar-width) + 47px * 2); */
}

.content {
  flex: 1;
  position: relative;
  padding: 47px 47px 47px calc(47px / 2);
  min-width: 600px;
  box-sizing: border-box;
}
