.sidebar {
  width: var(--sidebar-width);
  flex-shrink: 0;
  height: calc(100vh - var(--navbar-height));
  overflow-y: auto;
  overflow-x: hidden;
  padding: 47px calc(47px / 2) 0 47px;
  background-color: var(--true-white);
  z-index: 1;
  box-sizing: border-box;
  position: sticky;
  top: var(--navbar-height);
}
