.label {
  height: 55px;
  margin-bottom: 47px;
  max-width: 350px;
  width: 100%;
}

.sections {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
}

.zones {
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  transition: all 0.2s linear;
  color: var(--black);
  margin-left: 16px;
  user-select: none;
}

.zones.active {
  color: var(--indivd-orange);
  text-decoration: underline;
}

.createZone {
  margin-left: auto;
}
