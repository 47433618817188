.input {
  appearance: none; /* Remove iOS Safari input shadow */
  display: block;
  box-sizing: border-box;
  width: 100%;
  resize: none;
  padding: 7px 16px;
  outline: none;
  border: none;
  font-weight: 600;
  font-size: 13px;
}

.input.dashed {
  border: dashed 2px var(--black-40-);
  clip-path: polygon(
    calc(0% + 1px) calc(0% + 1px), /* top left */
    calc(100% - 1px) calc(0% + 1px), /* top right */
    calc(100% - 1px) calc(100% - 1px), /* bottom right */
    calc(0% + 1px) calc(100% - 1px) /* bottom left */
  );
  transition: color 0.2s linear, clip-path 0.2s linear;
}

.input.disabled {
  color: var(--black-40-);
}

.input.disabled.blackDisabled {
  background-color: #1E1E1E;
}

.input::placeholder {
  user-select: none;
  font-weight: 400;
  color: var(--black-40-);
  transition: color 0.2s ease;
}

.input.dashed:hover {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.input:hover::placeholder  {
  color: var(--black);
}

.input.dashed:not(:placeholder-shown) {
  border-color: var(--black);
  clip-path: polygon(
    calc(0% + 1px) calc(0% + 1px), /* top left */
    calc(100% - 1px) calc(0% + 1px), /* top right */
    calc(100% - 1px) calc(100% - 1px), /* bottom right */
    calc(0% + 1px) calc(100% - 1px) /* bottom left */
  );
}

.input.dashed.disabled:not(:placeholder-shown) {
  border-color: var(--black-40-);
}

.input.dashed.invalid {
  border-color: var(--error);
}

.input.invalid {
  border: 2px solid var(--error);
}

.validation {
  color: var(--error);
  font-size: 12px;
  margin-top: 2px;
}

.info {
  color: var(--black-40-);
  font-size: 13px;
  margin-top: 2px;
}

.inputContainer {
  position: relative;
}

.rightIcon {
  position: absolute;
  bottom: 8px;
  right: 20px;
}
