.accordion {
  margin: 22px 0 0 0;
}

.trigger {
  cursor: pointer;
  display: inline-flex;
  align-items: center;
  color: var(--black-40-);
  font-size: 14px;
  user-select: none;
}

.trigger > svg {
  margin-right: 8px;
}

.arrow {
  transition: transform 0.2s linear;
}

.arrow.revert {
  transform: rotateX(180deg);
}

.content {
  display: none;
}

.content.expanded {
  display: block;
}
