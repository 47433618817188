.chart {
  box-sizing: border-box;
  flex-grow: 1;;
}

.chart-1-3 {
  flex-basis: calc(33.3333% - 16px);
  min-width: calc(33.3333% - 16px);
}

.chart-2-3 {
  flex-basis: calc(66.6666% - 16px);
  min-width: calc(66.6666% - 16px);
}

@media (max-width: 1400px) {
  .chart-1-3 {
    flex-basis: 100%;
    min-width: 100%;
  }

  .chart-2-3 {
    flex-basis: 100%;
    min-width: 100%;
  }
}
