.dropdown {
  user-select: none;
}

.list {
  margin: 0;
  padding: 0;
  list-style: none;
}

.item {
  padding: 8px 8px 8px 8px;
  transition: background-color .2s linear;
  cursor: pointer;
}

.item:hover {
  background-color: #F5F5F5;
}

.item > .label {
  font-size: 14px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.item > .label > svg {
  margin-right: 8px;
  flex-shrink: 0;
}
