.row {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 30px 47px 0 47px;
}

.row > label:not(:last-child) {
  margin-right: 8px;
}

.label {
  color: var(--true-white);
  width: 100%;
  line-height: 24px;
}

.label > * {
  color: var(--black);
}

.openingHours {
  font-size: 14px;
  font-weight: 600;
  color: var(--true-white);
  user-select: none;
}

.label.inline {
  display: flex;
  align-items: center;
  margin-bottom: a;
}

.status {
  width: 100%;
  color: var(--true-white);
}

.toggle {
  white-space: nowrap;
}

.toggle label {
  background: var(--black) !important;
}

.toggle input + div {
  color: #B02523 !important;
}

.toggle input:checked + div {
  color: #75D088 !important;
}

.toggle input + div > label:after {
  background: #B02523 !important;
}

.toggle input:checked + div > label:after {
  background: #75D088 !important;
}
