.hiddenTextArea {
  opacity: .01;
  height: 0;
  position: absolute;
  z-index: -10;
  border: none;
  appearance: none;
  color: transparent;
  background-color: transparent;
}
