.tableContainer {
  width: 100%;
  overflow-x: scroll;
  margin-bottom: 42px;
  position: relative;
}

.tableContainer.noOverflow {
  overflow: hidden;
}

.loader {
  background-color: rgba(255, 255, 255, .75);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1;
  display: none;
}

.loader.visible {
  display: flex;
  justify-content: center;
}

.spinner {
  margin-top: 200px;
}

.table {
  margin: 0;
  table-layout: auto;
  border-collapse: collapse;
  width: 100%;
}

.table tbody tr {
  transition: background-color 0.2s linear;
}

.table tbody tr:hover {
  background-color: var(--true-white);
}

.table tbody tr:hover .edit {
  opacity: 1;
}

.col {
  /* You can set a fixed width here only if the "table-layout: fixed" on .table class */
  /* width: 190px; */
}

.cell {
  max-width: 250px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

.editCol {
  width: 76px;
}

.header {
  pointer-events: none;
}

.sortable {
  pointer-events: all;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  min-height: 21px;
}

.sortable svg {
  margin-left: 4px;
}

.sortable svg path {
  fill: #fff;
}


.element {
  vertical-align: middle;
  position: relative;
  background-color: #F9FAFA;
  height: 47px;
  font-size: 14px;
}

.element.selector {
  background-color: var(--black);
  color: var(--true-white);
  font-weight: 600;
  user-select: none;
}

.element.even {
  background-color: hsl(0, 0%, 96%);
}
.element.grayedOut {
  color: var(--black-40-);
}

.element > *:not(.checkbox) {
  padding: 0 16px 0 16px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  flex-shrink: 0;
}

.edit {
  display: inline-flex;
  color: var(--indivd-orange);
  width: 60px;
  opacity: 0;
  align-items: center;
  font-weight: 600;
  cursor: pointer;
  user-select: none;
  transition: opacity 0.2s linear;
}

.edit:hover {
  text-decoration: underline;
}

.edit > svg {
  margin-right: 8px;
}

.checkbox {
  vertical-align: middle;
  position: relative;
  user-select: none;
  width: 106px;
}

.checkbox > div {
  margin-left: 30px;
}

.selected {
  font-weight: 400;
  color: var(--black-40-);
  margin-right: 35px;
  width: 100%;
}

.pagination {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 32px 0 32px 0;
  background: #fff;
  /* position: fixed;
  left: var(--sidebar-width);
  right: 0;
  bottom: 0; */
  position: sticky;
  bottom: 0;
  z-index: 1;
}

.disabled {
  color: var(--black-40-);
}

.disabled > svg > path {
  stroke: var(--black-40-);
}

.pageNumbers {
  display: flex;
}

.pageNumber {
  font-size: 15px;
  width: 16px;
  height: 16px;
  color: var(--indivd-orange);
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
  margin: 4px;
}

.pageNumber:hover {
  background-color: var(--indivd-orange-40-);
}

.pageNumber.current {
  background-color: var(--indivd-orange);
  color: #fff;
  pointer-events: none;
}

.next {
  margin-left: 22px;
}

.prev {
  margin-right: 22px;
}

.prev,
.next {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: var(--indivd-orange);
  user-select: none;
  cursor: pointer;
}

.prev svg {
  margin-right: 16px;
}

.next svg {
  margin-left: 16px;
}

.prev svg path,
.next svg path {
  stroke: var(--indivd-orange);
}

.prev:hover,
.next:hover {
  text-decoration: underline;
}

.prev.disabled,
.next.disabled {
  color: var(--black-40-);
  pointer-events: none;
}

.prev.disabled svg path,
.next.disabled svg path {
  stroke: var(--black-40-);
}

.perpageCount {
  display: flex;
  align-items: center;
  font-size: 15px;
  color: var(--indivd-orange);
  user-select: none;
  cursor: pointer;
  margin-left: 62px;
}

.perpageCount:hover {
  text-decoration: underline;
}

.perpageCount svg path {
  stroke: var(--indivd-orange);
}

.perpageCount svg {
  margin-left: 8px;
  transition: transform 0.2s linear;
}

.perpageCount.visible {
  text-decoration: underline;
}

.perpageCount.visible svg {
  transform: rotateX(180deg);
}

.dropdown {
  padding: 16px 24px;
  user-select: none;
}

.dropdown > .item {
  cursor: pointer;
  margin-bottom: 12px;
  color: var(--indivd-orange);
}

.dropdown > .item:hover {
  text-decoration: underline;
}

.dropdown > .item:last-child {
  margin-bottom: 0;
}

.dropdown > .item.active {
  pointer-events: none;
  display: none;
}

.noResults {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 36px;
  height: 300px;
  color: var(--black-40-);
}
