.dropdown {
  width: 285px;
  user-select: none;
}

.title {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  justify-content: center;
  font-size: 12px;
  color: rgba(0, 0, 0, .5);
  font-weight: 500;
  padding: 8px 8px 0 8px;
}

.title > .line {
  height: 1px;
  width: 100%;
  border-bottom: 1px solid rgba(112, 112, 112, .5);
  margin: 8px 8px 6px 8px;
}

.close {
  position: absolute;
  right: 5px;
  top: 8px;
  cursor: pointer;
  padding: 2px;
}

.list {
  margin: 0;
  padding: 0 0 8px 0;
  list-style: none;
}

.item {
  padding: 8px 8px 8px 8px;
  transition: background-color .2s linear;
  cursor: pointer;
}

.item:hover {
  background-color: #F5F5F5;
}

.item > .label {
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.item > .label > svg {
  margin-right: 8px;
}

.item > .description {
  opacity: .75;
  font-size: 12px;
  margin-top: 4px;
}
