.container {
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
}

.loader {
  height: 100%;
  position: relative;
}

.squares {
  display: flex;
  flex-direction: column;
  padding: 47px 47px 0 47px;
  height: calc(100% - 47px);
  justify-content: space-between;
}

.title {
  width: 327px;
  height: 47px;
  background-color: #F4F4F4;
}

.subtitle {
  width: 64.1509433962%;
  height: 31px;
  background-color: #F4F4F4;
}

.content {
  width: 100%;
  height: 48vh;
  background-color: #F4F4F4;
}

.twins {
  display: flex;
  justify-content: space-between;
}

.twin {
  height: 16vh;
  width: 44.3396226415%;
  background-color: #F4F4F4;
}
