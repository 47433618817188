.content {
  color: var(--off-white);
  user-select: none;
}

.columns {
  display: flex;
  margin: 0 46px;
  gap: 16px;
}

.image {
  user-select: none;
  pointer-events: none;
  padding-bottom: 48px;
  margin: auto 0;
}

.title {
  display: inline-flex;
  align-items: center;
  font-weight: 600;
  font-size: 18px;
}

.title > svg {
  margin-right: 8px;
}

.title > svg path {
  stroke: var(--off-white);
}

.title > svg circle {
  stroke: var(--black);
  fill: var(--black);
}

.h1 {
  font-size: 36px;
  margin: 16px 0 24px 0;
  font-weight: 600;
  max-width: 500px;
}

.ul {
  padding-left: 48px;
  margin: 0;
}

.ul > li {
  font-weight: 600;
}

.buttonGroup {
  margin: 28px 0 64px 0;
  display: inline-block;
  margin-left: 32px;
  width: 300px;
}

.buttonGroup > *:not(:last-child) {
  margin-bottom: 18px;
}
