.footer {
  background-color: var(--black);
  height: 70px;
  margin-top: auto;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 24;
}
