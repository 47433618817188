.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.label {
  color: var(--true-white);
  height: 55px;
  margin: 24px 47px 0 47px;
}

.label > * {
  color: var(--black);
}

.addition {
  display: inline-flex;
  justify-content: center;
  margin: auto 0;
}

.addition > .clickable {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.addition > .clickable.loading {
  cursor: not-allowed;
}

.addition .text {
  color: var(--indivd-orange);
  font-size: 14px;
  text-decoration: underline;
  margin-right: 8px;
  user-select: none;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 47px;
  background-color: var(--black);
  padding: 20px 47px;
}

.buttonContainer {
  width: 100%;
}

.button {
  width: 100%;
}

.toggleLabel {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--true-white);
  margin: 24px 47px 0 47px;
}

.toggleLabel:last-of-type {
  margin-bottom: 47px;
}

.toggle label {
  background: var(--black) !important;
}

.toggle input:checked + div > label:after {
  background: #75D088 !important;
}

.toggle input + div > label:after {
  background: var(--error) !important;
}

.toggle span {
  color: var(--error);
}

.toggle input:checked + div > span {
  color: #75D088;
}

.consent {
  background-color: var(--black-80-);
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 47px;
  bottom: 70px;
  left: 0;
  right: 0;
  z-index: 24;
}

.consent a {
  color: var(--indivd-orange);
  text-decoration: none;
}

.consentBox {
  display: flex;
  align-items: center;
  width: 100%;
  border: dashed 1px var(--black-40-);
  padding: 12px;
  padding-left: 17px;
  font-size: 12px;
}

.consentBox span {
  margin-left: 9px;
  font-size: 13px;
  color: var(--true-white);
}

.consentBox a {
  color: var(--true-white);
  text-decoration: underline;
}

.checked path:first-child {
  fill: #75D088;
}

.unchecked path {
  fill: var(--true-white);
}
