.row {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 30px 47px 0 47px;
}

.label {
  color: var(--true-white);
  width: 100%;
  line-height: 24px;
}

.label > * {
  color: var(--black);
}

.fourStepProgress {
  margin-top: 8px;
}

.checkbox {
  margin-top: 4px;
}

.checkboxLabel {
  font-size: 14px;
  color: var(--true-white);
}
