.loader {
  background-color: rgba(255, 255, 255, 1);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.spinner {
  margin-bottom: 10%;
}
