.react-datepicker {
  font-family: 'Poppins', sans-serif;
  font-size: 13px;
  font-weight: 400;
  border: none;
  padding: 8px;
  max-height: 293px;
  box-sizing: border-box;
}

.react-datepicker__header {
  padding: 0;
  border-radius: 0;
  border: none;
  background: transparent;
}

.react-datepicker__month {
  margin: 5px;
}

.react-datepicker__day {
  font-weight: 600;
  width: 31px;
  line-height: 31px;
  background-color: rgba(153, 153, 153, 0.1);
  user-select: none;
  margin: 3px;
}

.react-datepicker__day:hover {
  border-radius: 0;
}

.react-datepicker__day--selected,
.react-datepicker__day--selected:hover {
  background-color: var(--indivd-orange) !important;
  border-radius: 0;
}

.react-datepicker__day--weekend {
  background-color: rgba(153, 153, 153, 0.3);
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__day--keyboard-selected:hover,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-selecting-range:hover {
  background-color: var(--indivd-orange);
  border-radius: 0;
}

.react-datepicker__day--in-range,
.react-datepicker__day--in-range:hover {
  background-color: var(--indivd-orange);
  border-radius: 0;
}

.react-datepicker__day--disabled:hover {
  background-color: rgba(153, 153, 153, 0.3);
  color: #ccc;
  cursor: not-allowed;
}

.react-datepicker__day-names {
  display: none;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker-time__caption {
  display: none !important;
}

.react-datepicker__input-time-container {
  margin: 0;
  clear: none;
  width: auto;
  float: none;
  text-align: unset;
}

.react-datepicker-time__input-container {
  width: 100%;
}

.react-datepicker-time__input {
  margin: 0 !important;
  display: block !important;
}


/* Workaround for https://github.com/Hacker0x01/react-datepicker/issues/2930#issuecomment-1104174745 */
.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected,
.react-datepicker__quarter-text--keyboard-selected,
.react-datepicker__year-text--keyboard-selected {
  border-radius: inherit;
  background-color: inherit;
  color: inherit;
}
.react-datepicker__month--selected,
.react-datepicker__month--in-selecting-range,
.react-datepicker__month--in-range,
.react-datepicker__quarter--selected,
.react-datepicker__quarter--in-selecting-range,
.react-datepicker__quarter--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}
/* * * * * * * * * * * * ** * * * * * * */
