.wrapper {
  margin: 30px 0 0 0;
}

.title {
  color: var(--black-40-);
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  user-select: none;
}

.title > svg {
  margin-right: 8px;
}

.wrapper > label {
  margin-top: 12px;
}

.wrapper > :global(.checkbox) {
  margin-top: 24px;
}

.footer {
  background-color: var(--true-white);
  z-index: 18;
  position: fixed;
  bottom: 0;
  padding: 16px 47px;
  /* margin: 0 31px; */
  display: flex;
  justify-content: space-between;
  align-items: center;
  /* width: 300px; */
  left: 0;
  width: calc(var(--sidebar-width) - 94px);
}

.footer > div {
  flex: 1;
}

.footer > div:first-child {
  margin-right: 4px;
}

.footer > div:last-child {
  margin-left: 4px;
}
