.container {
  pointer-events: none;
  border: 1px solid var(--indivd-orange);
  width: 390px;
  height: 219.375px;
  background: #fff;
}

.canvas {
  pointer-events: none;
}

.spinnerContainer {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
