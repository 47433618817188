.row {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 30px 47px 0 47px;
}

.label {
  color: var(--true-white);
  width: 100%;
  line-height: 24px;
}

.label > * {
  color: var(--black);
}

.toggle label {
  background: var(--black-60-) !important;
}

.toggle input:checked + div > label:after {
  background: #75D088 !important;
}

/* .toggle input + div > label:after {
  background: var(--error) !important;
} */

/* .toggle span {
  color: var(--error);
} */

.toggle input:checked + div > span {
  color: #75D088;
}

.label.inline {
  display: flex;
  align-items: center;
  margin-bottom: a;
}

.status {
  width: 100%;
  color: var(--true-white);
}
