.text {
  cursor: pointer;
}

.filter {
  align-items: center;
  color: var(--black-80-);
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-between;
  padding: 4px 8px;
  transition: background-color 0.2s linear, color 0.2s linear;
  user-select: none;
}

.filter.hover {
  background-color: var(--indivd-orange-40-);
}

.filter.active {
  background-color: var(--indivd-orange);
  color: var(--off-white);
}

.options {
  cursor: pointer;
  transform: rotate(90deg);
}
