.tooltip {
  background-clip: padding-box;
  background-color: #fff;
  border: 1px solid rgba(0,8,16,.15);
  box-shadow: 0 4px 14px -2px rgb(0 8 16 / 8%);
  color: var(--black-80-);
  min-width: 160px;
  padding: 16px;
  font-size: 14px;
}
