.container {
  display: flex;
  flex-direction: column;
}

.search {
  align-self: flex-end;
  margin-bottom: 8px;
  min-width: 320px;
}
