.nav {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.nav > svg {
  margin-right: 12px;
}

.nav > svg > g {
  transition: stroke 0.2s linear, fill 0.2s linear;
}

.nav {
  color: var(--black-80-);
  text-decoration: none;
  font-size: 18px;
  font-weight: 500;
  margin-bottom: 22px;
  transition: color 0.2s linear;
}

.nav:global(.active) {
  color: var(--indivd-orange);
  text-decoration: underline;
}

.nav:global(.active) svg > g {
  stroke: var(--indivd-orange);
}

.nav:hover {
  text-decoration: underline;
}

.beta {
  margin-left: 8px;
}
