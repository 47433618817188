.container {
  display: flex;
  flex-direction: column;
}

.progress {
  width: 100%;
  display: flex;
  gap: 6px;
  height: 6px;
}

.step {
  width: 25%;
  height: 100%;
  background-color: #e8e8e8;
}

.progress.Weak .step.active {
  background-color: #ff0000;
}

.progress.Acceptable .step.active {
  background-color: #ffa701;
}

.progress.Strong .step.active {
  background-color: #91c243;
}

.progress.Secure .step.active {
  background-color: #00b800;
}

.strength {
  font-size: 13px;
  font-weight: 500;
  margin-left: auto;
  margin-top: 2px;
  display: flex;
  align-items: center;
}

.strength.Unavailable {
  color: #e8e8e8;
}

.strength.Weak {
  color: #ff0000;
}

.strength.Acceptable {
  color: #ffa701;
}

.strength.Strong {
  color: #91c243;
}

.strength.Secure {
  color: #00b800;
}

.tip {
  display: flex;
  align-items: center;
  user-select: none;
  cursor: pointer;
}

.tip > svg {
  margin-left: 4px;
}
