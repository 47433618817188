.container {
  display: flex;
  margin-right: 16px;
}

.container > svg {
  transition: stroke 0.2s linear;
}

.container:hover > svg {
  stroke: var(--indivd-orange);
}
