.checkbox {
  display: flex;
  align-items: center;
  /* justify-content: center; */
  position: relative;
  user-select: none;
}

.checkbox svg {
  cursor: pointer;
  left: 0;
  position: absolute;
}

.checkbox.disabled  svg {
  cursor: not-allowed;
}

.unchecked {
  right: 9px;
}

.uncheckedBig {
  right: 4px;
}

.unchecked.black {
  border: 1px solid var(--black);
}

.unchecked.white {
  border: 1px solid var(--true-white);
}

.unchecked.white path {
  fill: var(--black);
}

.label {
  margin-left: 4px;
  cursor: pointer;
  font-size: 15px;
}

.label.disabled {
  cursor: not-allowed;
}
