.container {
  display: flex;
  position: relative;
  margin-top: 12px;
}

.dropdown {
  position: absolute;
  z-index: 20;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0,8,16,.15);
  color: #333;
  box-shadow: 0 4px 14px -2px rgb(0 8 16 / 8%);
  display: none;
}

.dropdown.end {
  right: 0;
}

.dropdown.visible {
  display: flex;
}

.label:first-child {
  margin-right: 4px;
}

.label:last-child {
  margin-left: 4px;
}
