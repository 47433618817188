.buttonGroup {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  margin: 0 47px;
}

.buttonGroup.right {
  justify-content: flex-end;
}

.buttonGroup.left {
  justify-content: flex-start;
}

.buttonGroup > * {
  margin: 0 8px;
}

.buttonGroup > * > * {
  min-width: 100px;
}
