.container {
  /* height: 40px; */
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.button {
  --border-width: 1px;
  --padding: 4px;
  --horizontal-padding: 22px;

  display: inline-flex;
  align-items: center;
  justify-content: center;
  border: var(--border-width) solid var(--indivd-orange);
  padding: var(--padding) var(--horizontal-padding);
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  border-radius: 5px;
  transition: all 0.2s linear;
  /* height: calc(44px - var(--padding) * 2 - var(--border-width) * 2); */
  height: var(--button-height);
  user-select: none;
  position: relative;
  white-space: nowrap;
  box-sizing: border-box;
  width: 100%;
}

.button:focus {
  outline: none;
  text-decoration: underline;
}

.button:hover {
  text-decoration: none;
}

.button:focus:not(:focus-visible) {
  text-decoration: none;
}

.button.primary {
  background-color: transparent;
  color: var(--indivd-orange);
}

.button.primaryNegative {
  background-color: transparent;
  color: var(--error);
  border-color: var(--error);
}

.button.primaryPositive {
  background-color: transparent;
  color: var(--positive);
  border-color: var(--positive);
}

.button.secondary {
  background-color: var(--indivd-orange);
  color: var(--true-white);
}

.button.black {
  background-color: var(--black);
  color: var(--true-white);
  border-color: var(--black);
}

.button.positive {
  background-color: var(--positive);
  color: var(--true-white);
  border-color: var(--positive);
}

.button.negative {
  background-color: var(--error);
  color: var(--true-white);
  border-color: var(--error);
}

.button.disabled {
  pointer-events: none;
  opacity: .7;
}

.button.active {
  background-color: var(--indivd-dark-orange);
  border-color: var(--indivd-dark-orange-80-);
}

.button.active.black {
  background-color: var(--black-80-);
  border-color: var(--black);
}

.button.primary:hover {
  color: var(--indivd-orange-hover-);
  border-color: var(--indivd-orange-hover-);
}

.button.primary:active {
  color: var(--indivd-dark-orange);
  border-color: var(--indivd-dark-orange-80-);
}

.button.negative:active {
  background-color: var(--error-highlighted);
  border-color: var(--error-highlighted);
}

.button.positive:active {
  background-color: var(--positive-highlighted);
  border-color: var(--positive-highlighted);
}

.button.primaryNegative:active {
  color: var(--error-highlighted);
  border-color: var(--error-highlighted);
}

.button.primaryPositive:active {
  color: var(--positive-highlighted);
  border-color: var(--positive-highlighted);
}

.button.secondary:hover {
    background-color: var(--indivd-orange-hover-);
}

.button.secondary:active {
  background-color: var(--indivd-dark-orange);
  border-color: var(--indivd-dark-orange-80-);
}

.button.black:active {
  background-color: var(--black-80-);
  border-color: var(--black);
}

/* .button:hover {
  padding: calc(var(--padding) - 2px) var(--horizontal-padding);
} */

.button .children > svg {
  margin-right: 8px;
}

.button.black > svg circle,
.button.black > svg path {
  stroke: var(--true-white);
}

.loaderContainer {
  width: 100%;
  flex-shrink: 0;
  position: absolute;
  left: 50%;
}

.children {
  display: flex;
  align-items: center;
  justify-content: center;
}

.children.loading {
  opacity: 0;
}

.children {
  opacity: 1;
}
