@-webkit-keyframes lineSpinFadeLoader {
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

@keyframes lineSpinFadeLoader {
  50% {
    opacity: 0.3;
  }
  100% {
    opacity: 1;
  }
}

.loader {
  /* margin-top: auto;
  margin-bottom: auto; */
  width: 100%;
  position: absolute;
}

.lineSpinFadeLoader {
  position: relative;
  top: -4px;
  left: -4px;
}

.lineSpinFadeLoader.primary > div:nth-child(1) {
  top: 20px;
  left: 0;
}

.lineSpinFadeLoader.secondary > div:nth-child(1) {
  top: 10px;
  left: 0;
}

.lineSpinFadeLoader > div:nth-child(1) {
  -webkit-animation: lineSpinFadeLoader 1.2s -0.84s infinite ease-in-out;
          animation: lineSpinFadeLoader 1.2s -0.84s infinite ease-in-out;
}

.lineSpinFadeLoader.primary > div:nth-child(2) {
  top: 13.63636px;
  left: 13.63636px;
}

.lineSpinFadeLoader.secondary > div:nth-child(2) {
  top: 6.81818px;
  left: 6.81818px;
}

.lineSpinFadeLoader > div:nth-child(2) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-animation: lineSpinFadeLoader 1.2s -0.72s infinite ease-in-out;
          animation: lineSpinFadeLoader 1.2s -0.72s infinite ease-in-out;
}

.lineSpinFadeLoader.primary > div:nth-child(3) {
  top: 0;
  left: 20px;
}

.lineSpinFadeLoader.secondary > div:nth-child(3) {
  top: 0;
  left: 10px;
}

.lineSpinFadeLoader > div:nth-child(3) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation: lineSpinFadeLoader 1.2s -0.6s infinite ease-in-out;
          animation: lineSpinFadeLoader 1.2s -0.6s infinite ease-in-out;
}

.lineSpinFadeLoader.primary > div:nth-child(4) {
  top: -13.63636px;
  left: 13.63636px;
}

.lineSpinFadeLoader.secondary > div:nth-child(4) {
  top: -6.81818px;
  left: 6.81818px;
}

.lineSpinFadeLoader > div:nth-child(4) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-animation: lineSpinFadeLoader 1.2s -0.48s infinite ease-in-out;
          animation: lineSpinFadeLoader 1.2s -0.48s infinite ease-in-out;
}

.lineSpinFadeLoader.primary > div:nth-child(5) {
  top: -20px;
  left: 0;
}

.lineSpinFadeLoader.secondary > div:nth-child(5) {
  top: -10px;
  left: 0;
}

.lineSpinFadeLoader > div:nth-child(5) {
  -webkit-animation: lineSpinFadeLoader 1.2s -0.36s infinite ease-in-out;
          animation: lineSpinFadeLoader 1.2s -0.36s infinite ease-in-out;
}

.lineSpinFadeLoader.primary > div:nth-child(6) {
  top: -13.63636px;
  left: -13.63636px;
}

.lineSpinFadeLoader.secondary > div:nth-child(6) {
  top: -6.81818px;
  left: -6.81818px;
}

.lineSpinFadeLoader > div:nth-child(6) {
  -webkit-transform: rotate(-45deg);
          transform: rotate(-45deg);
  -webkit-animation: lineSpinFadeLoader 1.2s -0.24s infinite ease-in-out;
          animation: lineSpinFadeLoader 1.2s -0.24s infinite ease-in-out;
}

.lineSpinFadeLoader.primary > div:nth-child(7) {
  top: 0;
  left: -20px;
}

.lineSpinFadeLoader.secondary > div:nth-child(7) {
  top: 0;
  left: -10px;
}

.lineSpinFadeLoader > div:nth-child(7) {
  -webkit-transform: rotate(90deg);
          transform: rotate(90deg);
  -webkit-animation: lineSpinFadeLoader 1.2s -0.12s infinite ease-in-out;
          animation: lineSpinFadeLoader 1.2s -0.12s infinite ease-in-out;
}

.lineSpinFadeLoader.primary > div:nth-child(8) {
  top: 13.63636px;
  left: -13.63636px;
}

.lineSpinFadeLoader.secondary > div:nth-child(8) {
  top: 6.81818px;
  left: -6.81818px;
}

.lineSpinFadeLoader > div:nth-child(8) {
  -webkit-transform: rotate(45deg);
          transform: rotate(45deg);
  -webkit-animation: lineSpinFadeLoader 1.2s 0s infinite ease-in-out;
          animation: lineSpinFadeLoader 1.2s 0s infinite ease-in-out;
}

.lineSpinFadeLoader.primary > div {
  background-color: #5168D6;
  width: 5px;
  height: 15px;
}

.lineSpinFadeLoader.secondary > div {
  background-color: var(--true-white);
  width: 2px;
  height: 5px;
}

.lineSpinFadeLoader.secondary.colorful > div {
  background-color: var(--indivd-orange);
}

.lineSpinFadeLoader > div {
  border-radius: 2px;
  margin: 2px;
  -webkit-animation-fill-mode: both;
          animation-fill-mode: both;
  position: absolute;

}
