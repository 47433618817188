.filler {
  height: 33px;
  position: relative;
}

.select {
  position: absolute;
  top: 0;
  background-color: var(--true-white);
  width: 100%;
}

.select.disabled {
  background-color: #fafafa;
}

.select.expanded {
  z-index: 1;
}

.input {
  appearance: none; /* Remove iOS Safari input shadow */
  border: 1px solid var(--true-white);
  box-sizing: border-box;
  cursor: pointer;
  font-size: 13px;
  height: 33px;
  line-height: 21px; /* To fix vertical aligning of the current text value */
  max-height: calc(calc(33 * 6px) + 2px); /* Total height + border width */
  min-width: 5px;
  outline: none;
  overflow: hidden;
  padding: calc(10px / 2) 36px calc(10px / 2) 16px;
  position: relative;
  text-overflow: ellipsis;
  transition: color 0.2s linear, clip-path 0.2s linear;
  white-space: nowrap;
  width: 100%;
}

.input.dashed {
  border: dashed 2px var(--black-40-);
  clip-path: polygon(
    calc(0% + 1px) calc(0% + 1px), /* top left */
    calc(100% - 1px) calc(0% + 1px), /* top right */
    calc(100% - 1px) calc(100% - 1px), /* bottom right */
    calc(0% + 1px) calc(100% - 1px) /* bottom left */
  );
  line-height: initial;
}

.input.dashed.invalid {
  border-color: var(--error);
  color: var(--error);
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.input.invalid {
  border: 2px solid var(--error);
  color: var(--error);
}

.input.disabled {
  color: var(--black-40-);
  pointer-events: none;
}

.input.disabled {
  color: var(--black-40-);
}

.input.disabled.blackDisabled {
  background-color: #1E1E1E;
  border: none;
}

.input:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: var(--black-40-);
  transition: color 0.2s ease;
  background-color: transparent;
}

.input.dashed:hover {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.input:empty:hover:before  {
  color: var(--black);
}

.input:not(:empty) {
  font-weight: 600;
}

.input.dashed:not(:empty) {
  border-color: var(--black);
  clip-path: polygon(
    calc(0% + 1px) calc(0% + 1px), /* top left */
    calc(100% - 1px) calc(0% + 1px), /* top right */
    calc(100% - 1px) calc(100% - 1px), /* bottom right */
    calc(0% + 1px) calc(100% - 1px) /* bottom left */
  );
}

.input.dashed.disabled:not(:placeholder-shown) {
  border-color: var(--black-40-);
}

.input br {
  display: none;
}

.input * {
  display: inline;
  white-space: nowrap;
}

.arrow {
  position: absolute;
  right: 20px;
  top: 13px;
  pointer-events: none;
}

.options {
  list-style: none;
  padding: 0;
  margin: 0;
  position: absolute;
  top: 33px;
  overflow: hidden;
  left: 2px;
  right: 2px;
  bottom: 2px;
  cursor: pointer;
  user-select: none;
  outline: none;
  overflow-y: scroll;
}

.option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 33px;
  background-color: #F5F5F5;
  padding-left: 16px;
}

.option > .label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin-right: auto;
}

.option.odd {
  background-color: #EDEBEB;
}

.option.selected {
  font-weight: 600;
}

.option.active,
.option:hover {
  background-color: #E5E5E5;
}

.spinnerContainer {
  position: absolute;
  right: -2px;
  top: -8px;
  bottom: 0;
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 6px;
  margin-top: 4px;
}

.tag {
  max-width: 120px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border: 1px solid var(--indivd-orange);
  border-radius: 20px;
  padding: 4px 28px 4px 16px;
  font-weight: 600;
  color: var(--indivd-orange);
  position: relative;
}

.tag > svg {
  position: absolute;
  top: 8px;
  right: 10px;
  cursor: pointer;
}

.validation {
  background: var(--error);
  color: #fff;
  font-size: 12px;
  padding-left: 16px;
  padding-bottom: 2px;
  border: 2px solid var(--error);
}

.info {
  color: var(--black-40-);
  font-size: 13px;
  margin-top: 2px;
}
