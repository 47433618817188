.titleWithBreadcrumb {
  display: flex;
  align-items: center;
}

.title {
  flex-shrink: 0;
  line-height: 41px;
}

.entityCount {
  font-size: 14px;
  color: var(--black-40-);
  margin-top: auto;
  margin-left: 5px;
  user-select: none;
}

.spinner {
  margin-bottom: 8px;
}
