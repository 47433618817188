.timeInput {
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* padding: 0 12px; */
  margin: 10px 10px 0 10px;
}

.label {
  color: var(--black-40-);
  user-select: none;
}

.input {
  font-weight: 600;
  width: 71px !important;
  padding: 0;
  text-align: center;
}
