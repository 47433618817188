.header {
  padding: 0 var(--nav-padding);
  width: 100%;
  box-sizing: border-box;
  min-width: var(--min-right-layout-width);
  border-bottom: 1px solid var(--line-black);;
  position: fixed;
  z-index: 50;
  background-color: var(--true-white);
}

.header > nav {
  display: flex;
  align-items: center;
  height: var(--navbar-height);
}

.sidebarside {
  width: calc(var(--sidebar-width) - var(--nav-padding));
}

.firstNav {
  margin-left: calc(47px / 2);
}

/* .header > nav > div:not(:last-child) {
  margin-left: auto;
} */
