.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 18px;
  margin: 5px 10px;
}

.label {
  color: var(--black-40-);
  user-select: none;
}

.current {
  display: flex;
  align-items: center;
}

.arrow {
  cursor: pointer;
  padding: 5px;
  padding: 0 10px 0 0;
}

.arrow.rotate {
  transform: rotate(180deg);
}

.arrow.disabled {
  pointer-events: none;
}

.arrow.disabled path {
  stroke: rgba(153, 153, 153, 0.4);
}

.select {
  /* text-align: center;
  text-align-last: center; */
  appearance: none;
  border: none;
  display: inline;
  background: transparent;
  outline: none;
  font-weight: 600;
  user-select: none;
}

.hidden {
  visibility: hidden;
  position: absolute;
  pointer-events: none;
  user-select: none;
}
