.container {
  display: flex;
  flex-direction: column;
  height: 100%;
  min-height: 500px;
}

.gap {
  margin-top: 50px;
}

.item {
  background-color: rgba(0, 0, 0, 1);
  height: 47px;
  display: flex;
  align-items: center;
  /* justify-content: space-between; */
  padding: 0 50px;
  color: var(--true-white);
  font-size: 14px;
}

.item.even {
  background-color: rgba(0, 0, 0, .4);
}

.meta > span {
  margin-right: 20px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.meta {
  width: 100%;
  display: flex;
  justify-content: center;
}

.email {
  width: 30%;
}

.organization {
  width: 25%;
}

.location {
  width: 15%;
}

.role {
  width: 10%;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  margin-top: auto;
  background-color: var(--black);
  padding: 0 47px;
}

.buttonGroup {
  margin-left: auto;
  display: inline-flex;
}

.submit {
  min-width: 130px;
}

.approveContainer {
  margin-left: 10px;
}

.selectedCount {
  color: var(--black-40-);
}

.selectedCount > b {
  color: var(--indivd-orange);
}

.spinnerContainer {
  position: absolute;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  margin-top: 125px;
}

.spinner {
  margin-bottom: 80px;
}

.list {
  height: 470px;
  overflow-y: scroll;
}
