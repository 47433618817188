.header {
  user-select: none;
}

.top {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}

.bottom {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  bottom: 7px;
}

.label {
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px 22px 6px 16px;
  position: relative;
  right: 23px;
  background-color: var(--black);
  box-sizing: border-box;
  flex-shrink: 0;
}

.label > .title {
  font-size: 23px;
  color: var(--true-white);
  margin-left: 31px;
  font-weight: 600;
  user-select: none;
  max-width: 300px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.close {
  box-sizing: content-box;
  padding: 16px;
  background-color: var(--black);
  cursor: pointer;
  position: relative;
  left: 24px;
  bottom: 24px;
}

.children {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
