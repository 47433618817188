.toast {
  padding: 0 47px;
  color: var(--true-white);
  height: 47px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.toast.success {
  background-color: var(--positive);
}

.toast.warning {
  background-color: var(--notice);
}

.toast.failure {
  background-color: var(--error);
}

.message {
  font-weight: 600;
}

.action {
  font-weight: 600;
  cursor: pointer;
  margin-left: auto;
  outline: none;
}

.action:focus,
.action:hover {
  text-decoration: underline;
}

.close {
  cursor: pointer;
  padding: 2px;
}
