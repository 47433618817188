.container {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-left: auto;
  cursor: pointer;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  transition: background-color 0.2s linear, border-color 0.2s linear;
  border: 1px solid #fff;
  flex-shrink: 0;
}

.container:hover {
  background-color: var(--indivd-orange);
  border-color: var(--indivd-orange);
}

.container:hover svg g {
  transition: stroke 0.2s linear;
}

.container:hover svg g {
  stroke: var(--off-white);
}
