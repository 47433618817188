.toggle {
  display: block;
}

.toggle > .label {
  padding-right: 10px;
  pointer-events: none;
  width: 100%;
  color: var(--primary-color);
  font-size: 13px;
  user-select: none;
  margin-bottom: 15px;
}

.tgl {
  display: none;
}

.tgl, .tgl:after,
.tgl:before,
.tgl *,
.tgl *:after,
.tgl *:before,
.tgl + div > .tglButton {
  box-sizing: border-box;
}

.tgl::selection,
.tgl:after::selection,
.tgl:before::selection,
.tgl *::selection,
.tgl *:after::selection,
.tgl *:before::selection,
.tgl + div > .tglButton::selection {
  background: none;
}

.tgl + div {
  display: flex;
  align-items: center;
}

.tgl + div > .description {
  font-size: 13px;
  margin-left: 18px;
  color: var(--primary-color);
}

.tgl + div > .tglButton {
  outline: 0;
  display: block;
  width: 48px;
  height: 24px;
  position: relative;
  cursor: pointer;
  user-select: none;
}


.tgl + div > .tglButton:after,
.tgl + div > .tglButton:before {
  position: relative;
  display: block;
  content: "";
  width: 50%;
  height: 100%;
}

.tgl + div > .tglButton:after {
  left: 0;
}

.tgl + div > .tglButton:before {
  display: none;
}

.tgl:checked + div > .tglButton:after {
  left: 50%;
}

.tglLight + div > .tglButton {
  background: #E5E5E5;
  border-radius: 20px;
}

.tglLight + div > .tglButton:after {
  border-radius: 50%;
  background: var(--black-40-);
  transition: all 0.2s ease;
}

.tglLight:checked + div > .tglButton {
  background: #EBEBEB;
}

.tglLight:checked + div > .tglButton:after {
  background-image: linear-gradient(to right, #75D088, #80B48B);
}

.tglLight.disabled:checked + div > .tglButton {
  background: #E5E5E5;
}

.tglLight.disabled:checked + div > .tglButton:after {
  background-image: none;
}

.prefix {
  position: relative;
}

.prefix .off,
.prefix .on {
  margin-right: 12px;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  right: 100%;
}

.prefix .off {
  color: var(--black-40-);
}

.prefix.disabled .off,
.prefix.disabled .on {
  margin-right: 12px;
  font-size: 13px;
  font-weight: 600;
  position: absolute;
  right: 100%;
  color: var(--black-40-);
}
