.optionsBar {
  display: flex;
}

.optionsBar > * {
  margin-right: 35px;
}

.selected {
  font-weight: 400;
  color: var(--black-40-);
}

.optionsBar > *:not(.selected) {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
}

.optionsBar > * > svg {
  margin-right: 8px;
}

.action {
  display: flex;
  align-items: center;
}
