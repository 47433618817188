.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 55;
  user-select: none;
}

.modal {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 56;
}

.modal > .window {
  display: flex;
  flex-direction: column;
  margin: 30px 0;
  position: relative;
}

.content {
  height: 100%;
}
