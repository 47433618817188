.lineWrapper {
  width: 100%;
}

.line {
  border-bottom: 1px solid #444444;
  box-sizing: border-box;
  height: 1px;
  margin: 24px 47px 0 47px;
}
