.sections {
  position: relative;
  user-select: none;
  display: inline-block;
}

.indicator {
  display: inline-flex;
  height: 34px;
  align-items: center;
  position: absolute;
  top: 0;
  z-index: 1;
  background-color: var(--black);
  color: var(--true-white);
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;
}

/* Section CSS */

.section {
  display: inline-flex;
  height: 34px;
  color: var(--black);
  align-items: center;
  padding: 0 16px;
  font-size: 14px;
  font-weight: 600;
  background-color: #FAFAFA;
  cursor: pointer;
}

.section.disabled {
  pointer-events: none;
  color: var(--black-40-);
}
