.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 47px;
  height: 47px;
  user-select: none;
}

.left {
  margin-left: -24px;
  display: flex;
  align-items: center;
}

.label {
  display: inline-flex;
  align-items: center;
  background-color: var(--black);
  color: var(--true-white);
  padding: 6px 24px 6px 16px;
}

.children {
  align-self: center;
}

.label > svg g {
  stroke: var(--true-white);
}

.title {
  font-size: 24px;
  font-weight: 600;
  margin-left: 27px;
}

.right {
  display: inline-flex;
}

.right > svg {
  padding: 16px;
  background-color: var(--black);
  cursor: pointer;
}
