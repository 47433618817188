.id {
  color: var(--black-40-);
  font-size: 14px;
  margin-right: 16px;
}

.id > b {
  font-weight: 600;
}

.row {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 30px 47px 0 47px;
}

.label {
  color: var(--true-white);
  width: 100%;
  line-height: 24px;
}

.label > * {
  color: var(--black);
}

