.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.right {
  display: inline-flex;
  align-items: center;
  font-size: 14px;
  color: var(--black-40-);
  user-select: none;
}

.right b {
  font-weight: 600;
  user-select: all;
}

.button {
  margin-left: 25px;
}

@media only screen and (max-width: 1080px) {
  .right > span {
    display: none;
  }
}
