.button {
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 13px;
  color: #fff;
  user-select: none;
  cursor: pointer;
  transition: color .1s linear;
}

.button.active {

}

.button.disabled {
  color: #919191;
  cursor: not-allowed;
}

.button > svg {
  margin-bottom: 3px;
}

.button > svg g {
  stroke: #fff;
  transition: stroke .1s linear;
}

.button > svg > g > rect {
  /* Rect stroke and rect fill is animating separately that
     is why transition time is one tenth of the rest  */
  transition: stroke .01s linear;
}

.button.disabled > svg g {
  stroke: #919191;
}

.button.disabled > svg > g > rect {
  fill: #919191;
}

.button:not(.disabled):hover {
  color: var(--indivd-orange);
}

.button:not(.disabled):hover > svg g {
  stroke: var(--indivd-orange);
}

.button:not(.disabled):hover > svg > g > rect {
  fill: var(--indivd-orange);
}

.button:not(.disabled).active {
  color: var(--indivd-orange);
}

.button:not(.disabled).active > svg g {
  stroke: var(--indivd-orange);
}

.button:not(.disabled).active > svg > g > rect {
  fill: var(--indivd-orange);
}
