.colors {
  display: grid;
  gap: 10px;
  grid-template-columns: repeat(5, 50px);
  grid-template-rows: repeat(2, 50px);
}

.color {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  display: inline-block;
  box-sizing: border-box;
  cursor: pointer;
  transition: border .2s;
}

.color.disabled {
  pointer-events: none;
  opacity: .5;
}

.color.selected {
  border: 2px solid #fff;
  opacity: 1;
}
