.input:checked,
.input:not(:checked) {
    position: absolute;
    left: -9999px;
}
.input:checked + label,
.input:not(:checked) + label
{
    position: relative;
    padding-left: 28px;
    cursor: pointer;
    line-height: 20px;
    display: inline-flex;
    align-items: center;
    color: #666;
}
.input:checked + label:before,
.input:not(:checked) + label:before {
    content: '';
    position: absolute;
    left: 0;
    width: 12px;
    height: 12px;
    border: 1px solid #ddd;
    border-radius: 100%;
    background: var(--true-white);
}
.input:checked + label:after,
.input:not(:checked) + label:after {
    content: '';
    width: 10px;
    height: 10px;
    background: var(--indivd-orange);
    position: absolute;
    left: 2px;
    border-radius: 100%;
    -webkit-transition: all 0.2s ease;
    transition: all 0.2s ease;
}
.input:not(:checked) + label:after {
    opacity: 0;
    -webkit-transform: scale(0);
    transform: scale(0);
}
.input:checked + label:after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}
