.dp {
  width: 32px;
  height: 32px;
  object-fit: cover;
  border-radius: 50%;
  background-color: var(--off-white);
  cursor: pointer;
  border: 1px solid var(--indivd-orange);
  box-sizing: border-box;
  margin-left: 30px;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 500;
  color: var(--indivd-orange);
  transition: background-color 0.2s linear, color 0.2s linear;
}

.dp:hover,
.dp.active {
  background-color: var(--indivd-orange);
  color: var(--off-white);
}
