.action {
  display: inline-flex;
  align-items: center;
  margin: 0 auto;
  user-select: none;
  cursor: pointer;
  color: var(--error);
  font-size: 14px;
  transition: color 0.2s linear;
}

.action:hover {
  color: var(--indivd-orange-hover-);
  text-decoration: underline;
}

.action > svg {
  margin-right: 4px;
  transition: stroke 0.2s linear;
}

.action > svg path {
  stroke: var(--error);
  transition: stroke 0.2s linear;
}

.action:hover > svg path {
  stroke: var(--indivd-orange-hover-);
}
