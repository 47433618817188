.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 51;
  user-select: none;
}

.foldout {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  min-height: 100%;
  position: fixed;
  top: 0;
  /* left: 0; */
  right: 0;
  bottom: 0;
  z-index: 52;
}

.foldout > .window {
  background-image: linear-gradient(to bottom, var(--black-80-), var(--black-60-));
  display: flex;
  flex-direction: column;
  position: relative;
  /* min-height: 800px; */
  height: 100vh;
}

.content {
  max-height: 100vh;
  /* display: grid; */

  height: 100%;
  display: flex;
  flex-direction: column;
}

.content.md {
  width: 388px;
}

.content.lg {
  width: 874px;
}
