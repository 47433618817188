[class^="arrow-"] {
  width: 0;
  height: 0;
  display: inline-block;
  vertical-align: middle;
  border-style: solid;
}
.arrow-up {
  border-width: 0 4px 4px 4px;
  border-color: transparent transparent #999 transparent;
}
.arrow-down {
  border-width: 4px 4px 0 4px;
  border-color: #999 transparent transparent transparent;
}
.arrow-left {
  border-width: 4px 4px 4px 0;
  border-color: transparent #999 transparent transparent;
}
.arrow-right {
  border-width: 4px 0 4px 4px;
  border-color: transparent transparent transparent #999;
}
