.foldout {
  overflow: hidden scroll;
}

.id {
  color: var(--black-40-);
  font-size: 14px;
  width: 366px; /* TODO: Make it dynamic: It should be same width with the foldout input fields */
}

.id > b {
  font-weight: 600;
}

.row {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin: 30px 47px 0 47px;
}

.row > .label:nth-child(1) {
  margin-right: 24px;
}

.row > .label:nth-child(2) {
  margin-left: 24px;
}

.row.topless {
  margin-top: 9px;
}

.row.topless > div {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.row.topless > div > div:not(:last-child) {
  margin-right: 24px;
}

.row.aligned {
  align-items: flex-start;
}

.label {
  color: var(--true-white);
  /* margin-bottom: 24px; */
  width: 100%;
  line-height: 24px;
}

.label > * {
  color: var(--black);
}

.toggle label {
  background: var(--black-60-) !important;
}

.toggle input:checked + div > label:after {
  background: #75D088 !important;
}

/* .toggle input + div > label:after {
  background: var(--error) !important;
}

.toggle span {
  color: var(--error);
} */

.toggle input:checked + div > span {
  color: #75D088;
}

.label.inline {
  display: flex;
  align-items: center;
  margin-bottom: a;
}

.status {
  width: 100%;
  color: var(--true-white);
}

.tip {
  display: flex;
  align-items: center;
  color: #fff;
  font-size: 14px;
  text-decoration: underline;
  user-select: none;
  cursor: pointer;

  /* TODO: Remove when published */
  visibility: hidden;
  width: 60px;
  /* * * * * * * * * * * * * * * */
}

.tip > svg {
  margin-left: 16px;
}

.arrow {
  flex-shrink: 0;
  width: 40px;
  align-self: flex-end;
  /* margin-bottom: 4px; */
  position: relative;
  top: -4px;
}

.arrow > svg > g {
  stroke: #fff;
}

.infoWrapper > .info {
  font-size: 14px;
  color: var(--black-40-);
  margin-bottom: 8px;
}

.infoWrapper > .info:last-child {
  margin-bottom: 0;
}

.infoWrapper > .info > b {
  font-weight: 600;
  user-select: text;
}

.infoWrapper > .info > b:hover {
  cursor: text;
}

.infoWrapper > .info > svg {
  position: relative;
  top: 4px;
  margin-left: 6px;
}

.infoWrapper > .info > svg:hover {
  cursor: pointer;
}

.infoWrapper > .info > svg:hover path {
  fill: var(--indivd-orange);
}

.uploadDate {
  color: var(--black-40-);
  user-select: none;
  font-size: 14px;
  margin-left: auto;
  margin-bottom: 6px;
}

.noTopMargin {
  margin-top: 0;
}

.copyTooltip {
  padding: 6px;
  color: var(--indivd-orange);
  font-weight: 500;
}

.fullUrl {
  width: 100%;
  margin-left: 24px;
  align-items: center;
  display: flex;
}

.bottomLine {
  margin-top: 54px; /* 30 + 24 */
}
