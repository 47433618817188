.container {
  display: flex;
  flex-direction: column;
}

.buttonGroup {
  display: flex;
  align-items: center;
  height: 70px;
  background-color: var(--black);
  padding: 0 46px;
  margin-top: 30px;
}

.buttonGroup > *:last-child {
  margin-left: 8px;
}

.buttonGroup > *,
.buttonGroup > * > * {
  width: 100%;
}

.description {
  color: var(--true-white);
  margin: 0 46px;
  font-weight: 500;
  word-break: break-all;
  user-select: none;
}
