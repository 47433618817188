.tooltip .title {
  font-weight: 500;
}

.tooltip ul {
  padding-left: 0;
  list-style: none;
}

.tooltip ul li {
  display: flex;
  align-items: center;
  color: #E05A5A;
  padding: 2px 0;
}

.tooltip ul li.success {
  color: #2BA44F;
}

.tooltip ul li svg {
  margin-right: 4px;
}
