.select {
  position: absolute;
  background-color: var(--true-white);
  width: 100%;
}

.select.expanded {
  z-index: 1;
}

.input {
  outline: none;
  position: relative;
  cursor: pointer;
  width: auto;
  white-space: nowrap;
  min-width: 5px;
  overflow: hidden;
  appearance: none; /* Remove iOS Safari input shadow */
  box-sizing: border-box;
  width: 100%;
  height: 33px;
  padding: calc(10px / 2) 16px;
  border: 1px solid var(--true-white);
  font-size: 13px;
  transition: color 0.2s linear, clip-path 0.2s linear;
  line-height: 21px; /* To fix vertical aligning of the current text value */
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding-right: 50px;
}

.input.dashed {
  border: dashed 2px var(--black-40-);
  clip-path: polygon(
    calc(0% + 1px) calc(0% + 1px), /* top left */
    calc(100% - 1px) calc(0% + 1px), /* top right */
    calc(100% - 1px) calc(100% - 1px), /* bottom right */
    calc(0% + 1px) calc(100% - 1px) /* bottom left */
  );
  line-height: initial;
}

.input.disabled {
  color: var(--black-40-);
  background-color: #1E1E1E;
  border: none;
  pointer-events: none;
}

.input:empty:before {
  content: attr(placeholder);
  position: absolute;
  color: var(--black-40-);
  transition: color 0.2s ease;
  background-color: transparent;
}

.input.dashed:hover {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
}

.input:empty:hover:before  {
  color: var(--black);
}

.input:not(:empty) {
  font-weight: 600;
}

.input.dashed:not(:empty) {
  border-color: var(--black);
  clip-path: polygon(
    calc(0% + 1px) calc(0% + 1px), /* top left */
    calc(100% - 1px) calc(0% + 1px), /* top right */
    calc(100% - 1px) calc(100% - 1px), /* bottom right */
    calc(0% + 1px) calc(100% - 1px) /* bottom left */
  );
}

.input br {
  display: none;
}

.input * {
  display: inline;
  white-space: nowrap;
}

.arrow {
  position: absolute;
  right: 20px;
  top: 13px;
}

.options {
  padding: 0;
  margin: 0;
  position: absolute;
  top: 33px;
  overflow: hidden;
  left: 2px;
  right: 2px;
  bottom: 2px;
  user-select: none;
  outline: none;
}

.picker {
  display: flex;
}

.preset {
  display: flex;
  align-items: center;
}

.preset.noStartDate {
  padding: 0 21px 12px 21px;
  color: var(--indivd-orange);
}

.actions {
  display: flex;
  justify-content: space-between;
  padding: 12px 12px 12px 12px;
  min-height: 55px;
  box-sizing: border-box;
}

.buttons {
  margin: 0 10px;
  display: flex;
}

.buttons.withoutPreset {
  justify-content: center;
  width: 100%;
}

.apply {
  margin-left: 8px;
}
