.fileName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 200px;
  margin: 0 0 12px 0;
  font-size: 14px;
  font-weight: 500;
}

.button {
  margin-left: auto;
}
