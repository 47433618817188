.entityCount {
  color: var(--black-40-);
  font-size: 16px;
}

.entityCount > b {
  color: var(--indivd-orange);
  margin-right: 5px;
  font-weight: 600;
}
