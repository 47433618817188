@import url(./miscellaneous.css);
@import url(./dynamic-grids.css);

@font-face {
  font-family: 'Poppins';
  font-weight: 100;
  src: local('Poppins'), url(./fonts/Poppins-Thin.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 200;
  src: local('Poppins'), url(./fonts/Poppins-ExtraLight.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 300;
  src: local('Poppins'), url(./fonts/Poppins-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 400;
  src: local('Poppins'), url(./fonts/Poppins-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 500;
  src: local('Poppins'), url(./fonts/Poppins-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 600;
  src: local('Poppins'), url(./fonts/Poppins-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 700;
  src: local('Poppins'), url(./fonts/Poppins-Bold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 800;
  src: local('Poppins'), url(./fonts/Poppins-ExtraBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins';
  font-weight: 900;
  src: local('Poppins'), url(./fonts/Poppins-Black.ttf) format('truetype');
}

@font-face {
  font-family: 'Poppins 52';
  font-weight: 600;
  src: local('Poppins 52'), url(./fonts/Poppins52-SemiBold.ttf) format('truetype');
}

@import url('datepicker.css');
@import url('tippyjs.css');

:root {
  /* Colors */
  --indivd-orange: #D7512F;
  --indivd-orange-hover-: #cd3b16;
  --indivd-orange-80-: #DF7458;
  --indivd-orange-40-: #EFB9AB;
  --indivd-dark-orange: #8E2D14;
  --indivd-pinky-orange: #f0b59a;
  --indivd-pinky-orange-80-: #f9ded0;
  --indivd-dark-orange-80-: #A45743;
  --indivd-dark-orange-40-: #D2ABA1;
  --beige: #BEA699;
  --beige-80-: #CBB7AD;
  --beige-40-: #E5DBD6;
  --black: #000000;
  --black-80-: #222222;
  --black-60-: #333333;
  --black-40-: #999999;
  --line-black: #707070;
  --off-white: #FFFCF4;
  --off-white-80-: #FFFCF6;
  --off-white-40-: #FFFEFB;
  --true-white: #FFFFFF;
  --notice: #F0C668;
  --notice-highlighted: #BF9D51;
  --positive: #80B48B;
  --positive-highlighted: #65906D;
  --informative: #64C2EB;
  --informative-highlighted: #4F9ABB;
  --error: #B02524;
  --error-highlighted: #8C1C1A;

  /* Dimensions */
  --min-right-layout-width: 613px;
  --nav-padding: 47px;
  --sidebar-width: calc(300px + 47px + 47px / 2);
  --navbar-height: 90px;
  --button-height: 34px;
}

html,
body,
#root {
  height: 100%;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Poppins', sans-serif;
  font-size: 16px;
  font-weight: 400;
  /* overflow: hidden auto; */
}

textarea,
input,
button,
select {
  font-family: inherit;
  font-size: inherit;
}


.scroll::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  background-color: #FCFCFC;
  scrollbar-width: thin;
}

.scroll::-webkit-scrollbar-thumb {
  background-color: var(--indivd-orange-80-);
  border-radius: 6px;
}

.scroll::-webkit-scrollbar-track {
  background-color: #FCFCFC;
}
