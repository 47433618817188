.navItem {
  user-select: none;
}

.navItem > a {
  display: flex;
  align-items: center;
  text-decoration: none;
  font-size: 15px;
  color: var(--black);
  font-weight: 500;
}

.navItem > a:hover {
  text-decoration: underline;
}

.navItem > a:global(.active) {
  color: var(--indivd-orange);
  text-decoration: underline;
}

.navItem > a > span {
  margin-left: 12px;
}

.navItem > a:global(.active) > svg g {
  stroke: var(--indivd-orange);
}
