.form {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
}

.section {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
}

.label {
  height: 55px;
  margin-bottom: 24px;
  max-width: 350px;
  width: 100%;
}

.label.disabled {
  color: var(--black-40-);
}

.section > .column:first-child {
  margin-right: 47px;
}

.column {
  max-width: 350px;
  min-width: 320px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.row {
  display: flex;
}

.row > .label:first-child {
  margin-right: 8px;
}

.checkbox {
  margin-right: 11px;
}

.disabledInput {
  background-color: #CCCCCC;
}

.cancel,
.pro {
  align-items: center;
  border: dashed 2px var(--black-40-);
  box-sizing: border-box;
  display: flex;
  font-size: 14px;
  height: 47px;
  margin-top: 5px;
  padding: 14px;
  position: relative;
  clip-path: polygon(
    calc(0% + 1px) calc(0% + 1px), /* top left */
    calc(100% - 1px) calc(0% + 1px), /* top right */
    calc(100% - 1px) calc(100% - 1px), /* bottom right */
    calc(0% + 1px) calc(100% - 1px) /* bottom left */
  );
}

.pro {
  color: var(--black-40-);
  user-select: none;
  margin-bottom: 27px;
}

.cancel {
  background-color: var(--off-white);
}

.cancel > .action {
  color: var(--indivd-orange);
  text-decoration: underline;
  margin-left: 8px;
  user-select: none;
  cursor: pointer;
}

.footer {
  margin-top: 16px;
}

.loader {
  background-color: rgba(255, 255, 255, .75);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 2;
  display: flex;
  justify-content: center;
}

.spinner {
  margin-top: 300px;
}
