.label {
  color: var(--true-white);
  height: 55px;
  margin: 24px 47px 0 47px;
}

.label > * {
  color: var(--black);
}


.buttons {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin-top: 47px;
  background-color: var(--black);
  padding: 20px 47px;
}

.buttonContainer {
  width: 100%;
}

.button {
  width: 100%;
}
