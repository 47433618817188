.container {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.label {
  color: var(--true-white);
  height: 55px;
  margin-bottom: 24px;
  margin: 24px 47px 0 47px;
}

.label.heightless {
  height: auto;
}

.label > * {
  color: var(--black);
}

.addition {
  display: inline-flex;
  justify-content: center;
  margin: auto 0;
}

.addition > .clickable {
  cursor: pointer;
  display: flex;
  align-items: center;
}

.addition .text {
  color: var(--indivd-orange);
  font-size: 14px;
  text-decoration: underline;
  margin-right: 8px;
}

.buttonContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 70px;
  margin-top: 47px;
  background-color: var(--black);
}

.submitContainer {
  flex: 1;
}

.submit {
  width: 100%;
  margin: 0 47px;
}

.info {
  color: var(--black-40-);
  margin: 24px 47px 0 47px;
}

.onlyEmail {
  margin: 0 8px;
}


.invitees {
  color: var(--true-white);
}

.invitees > .title {
  margin: 24px 40px;
  font-weight: 600;
}

.invitees > .list > .invitee {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px 55px 15px 60px;
  font-size: 14px;
}

.invitees > .list > .invitee.odd {
  background-color: rgba(0, 0, 0, .2);
}

.invitees > .list > .invitee > .details {
  display: flex;
  align-items: center;
}

.invitees > .list > .invitee > .details > .detail > b {
  color: var(--indivd-orange);
}

.invitees > .list > .invitee > .details svg {
  cursor: pointer;
  padding: 8px;
  margin-left: 8px;
}

.sections {
  background-color: var(--black-60-);
  display: inline-flex;
  margin-right: 47px;
  user-select: none;
}

.sections > .section {
  height: 47px;
  width: 154px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--indivd-orange);
  font-size: 19px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.4s ease-in-out;
}

.sections > .section > svg {
  margin-right: 8px;
}

.sections > .section > svg circle,
.sections > .section > svg path {
  stroke: var(--indivd-orange);
}

.sections > .section.active {
  color: var(--true-white);
  background-color: var(--black);
}

.sections > .section.active > svg circle,
.sections > .section.active > svg path {
  stroke: var(--true-white);
}
